import React, { useState } from 'react'
import Contact from './Contact';
import Promotion from '../components/Promotion';
import ACCAPartnerLogo from '../images/acca-silver.png';

export default function Hero() {

    const [showPromo, setShowPromo] = useState(true);

    const handleClose = () => {
        setShowPromo(false);
    }    

    return (
        <section className="container hero">
            <img
                alt="ACCA approved Silver Learning Partner"
                src={ACCAPartnerLogo}
                placeholder="blurred"
                width={200}
                className='acca-partner-logo'
            />
            <h1 className="hero__title">
            Start your career with
                <br/>
            a term ahead.
            </h1>
            <h3 className="hero__subtitle">
            We'll help you take control of your studies and <br/>achieve
            your professional goals. 
                <br/>
            Every step of the way!</h3>
            <Contact className="hero__action"/>
            <Promotion open={showPromo} onClose={handleClose}/>
        </section>
    )
}
